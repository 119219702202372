<template>
    <div>
        <!-- Меню и фильтр, сворачивающийся блок -->
        <div class="menu-container" :class="{menu_closed: isCloseMenu}">
            <Menu/>
            <Filters :scrolled="true" :is-open="isOpen" @show="showAllParams" />
            <div class="bg">
                <Tags v-if="isMobile" />
            </div>
        </div>

        <Controls
                :title="`${count.currentCount} объектов`"
                :isCloseMenu="isCloseMenu"
                @switchMenu="switchMenu"
        />

        <div class="side-menu-container" v-if="!isMobile" :class="{side_menu_closed: isCloseMenu}">
            <Side :is-open="isOpen" />
        </div>

        <div :class="[isMobile ? 'mobile-warning-msg' : 'warning-msg']" v-if="mapLoading">
            Происходит построение карты.<br>
            Нужно немного подождать.
        </div>
        <preloader class="map_preloader" v-if="mapLoading || clusterLoading" />

        <yandex-map
                v-if="!loading && mapHomes"
                :class="{map_loading: mapLoading || clusterLoading}"
                class="map"
                :coords="mapCoords"
                :setings="settings"
                :controls=[]
                zoom="12"
                :use-object-manager="true"
                @map-was-initialized="getMapInstance"
        >
        </yandex-map>

        <!-- Боковая панель со страницей дома -->
       <Object 
                v-if="!objLoading && objectOpened && home"
                :isOpen="objectOpened"
                @closeHome="closeHome"
        />

        <Cluster 
                v-if="!clusterLoading && clusterOpened && clusterHomes"
                :isOpen="clusterOpened"
                @closeHome="closeCluster"
        />

    </div>
</template>

<script>
import { yandexMap } from 'vue-yandex-maps'
import Filters from '@/components/Filters'
import Tags from '@/components/Tags'
import Controls from '@/components/Map/Controls'
import Side from '@/components/Map/Side'
import Object from '@/components/Map/Object'
import Cluster from '@/components/Map/Cluster'
import { mapGetters, mapActions } from 'vuex'
import Preloader from '@/components/Preloader'

export default {
    components: {
        yandexMap, Controls, Filters, Object, Preloader, Side, Cluster, Tags
    },
    data() {
        return {
            isOpen: false,
            mapCoords: [56.03,92.85],
            objectOpened: false,
            clusterOpened: false,
            isCloseMenu: true,
            clusterMap: null,
            objectManager: null,
            mapLoading: false,
            settings: {
                apiKey: 'e70694c3-ce7f-4459-b7f6-be3d53e2cc8e',
                lang: 'ru_RU',
                version: '2.1',
            },
        }
    },
    watch: { 
        '$route': {
            handler() {
                this.parseQuery()
                this.fetchHomes({limit: false, map: true})
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        ...mapGetters(['homes', 'count', 'home', 'objLoading', 'clusterHomes', 'clusterLoading']),
    },
    created() {
        this.mapLoading = true;
    },
    methods: {
        ...mapActions(['fetchMapHome', 'fetchClusterHomes']),
        async getMapInstance(map) {
            if(map && this.homes) {
                this.clusterMap = map
                // eslint-disable-next-line no-undef
                this.objectManager = new ymaps.ObjectManager({
                    clusterize: true,
                    gridSize: 64,
                    clusterIconColor: "#3F709B",
                    preset: 'islands#nightClusterIcons',
                    clusterHideIconOnBalloonOpen: false,
                    openBalloonOnClick: false,
                    geoObjectHideIconOnBalloonOpen: false,
                    clusterDisableClickZoom: true
                })
                this.clusterMap.geoObjects.events.add('click', (e) => (this.clickPoint(e)))
                this.objectManager.add(this.mapHomes)
                this.clusterMap.geoObjects.add(this.objectManager)
                this.objectManager.objects.events.add(['mouseenter', 'mouseleave'], this.onObjectEvent);
                this.objectManager.clusters.events.add(['mouseenter', 'mouseleave'], this.onClusterEvent);
                this.mapLoading = false;
            }
        },
        onObjectEvent (e) {
            var objectId = e.get('objectId');
            if (e.get('type') == 'mouseenter') {
                this.objectManager.objects.setObjectOptions(objectId, {
                    iconColor: '#d19d19'
                });
            } else {
                let object = this.objectManager.objects.getById(objectId)
                this.objectManager.objects.setObjectOptions(objectId, {
                    iconColor: localStorage.getItem('ymap_ids') && localStorage.getItem('ymap_ids').includes(object.id)
                                ? '#75a3bd'
								: '#3F709B', 
                });
            }
        },
        onClusterEvent (e) {
            var objectId = e.get('objectId');
            if (e.get('type') == 'mouseenter') {
                this.objectManager.clusters.setClusterOptions(objectId, {
                    iconColor: '#d19d19'
                });
            } else {
                this.objectManager.clusters.setClusterOptions(objectId, {
                    iconColor: localStorage.getItem('ymap_clusters') && localStorage.getItem('ymap_clusters').includes(objectId)
                                ? '#75a3bd'
								: '#3F709B', 
                });
            }
        },
        clickPoint(e) {
            let target = e.get('objectId')
            if (this.objectManager.clusters.getById(target)) {
                let cluster = this.objectManager.clusters.getById(target)
                let objects = cluster.properties.geoObjects
                let buf = []
                objects.forEach(element => {
                    buf.push(element.id)
                })
                this.fetchClusterHomes(buf);
                this.openHomes(target);
            }
            else {
                let point = this.objectManager.objects.getById(target)
                this.fetchMapHome(point.id);
                this.openHome(point.id);
            }
        },
        openHome(id) {
            this.clusterOpened = false;
            this.objectOpened = true;
            let ids = JSON.parse(localStorage.getItem('ymap_ids'))
            if (!ids) ids = []
            ids.push(id)
            localStorage.setItem('ymap_ids', JSON.stringify(ids))
        },
        openHomes(id) {
            this.objectOpened = false;
            this.clusterOpened = true;
            let ids = JSON.parse(localStorage.getItem('ymap_clusters'))
            if (!ids) ids = []
            ids.push(id)
            localStorage.setItem('ymap_clusters', JSON.stringify(ids))
        },
        switchMenu() {
            this.isOpen = false;
            this.isCloseMenu = !this.isCloseMenu;
        },
        showAllParams() {
            this.isOpen = !this.isOpen;
        },
        closeHome() {
            this.objectOpened = false;
        },
        closeCluster() {
            this.clusterOpened = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.menu-container {
    position: relative;
    max-width: 100%;
    height: 125px;
    z-index: 2;
    transition: transform .25s;
}
.menu_closed {
    transform: translate(0, -145px);
}
.side-menu-container {
    position: relative;
    background: rgb(248, 248, 251);
    margin-top: 13px;
    height: 85.5vh;
    width: calc((100vw - 1220px) / 2);
    z-index: 240;
    transition: transform .25s;
}
.side_menu_closed {
    transform: translateX(-400px);
}
.map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    &_overlay {
        position: absolute;
        top: 138px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 234;
        background: rgba(155, 153, 126, 0.575);
    }
    &_loading {
        opacity: 0.2;
    }
    &_preloader {
        position: absolute;
        top: 50%;
        z-index: 100;
    }
}
.warning-msg {
    position: absolute;
    top: 40%;
    left: 43vw;
    z-index: 100;
    color: #445f7a;
    font: 17px proxima-regular;
    text-align: center;
}
.mobile-warning-msg {
    position: absolute;
    top: 40%;
    left: 20%;
    z-index: 100;
    color: #445f7a;
    font: 16px proxima-regular;
    text-align: center;
}  
.bg {
    background: white;
    padding: 0 20px 10px 20px;
}
</style>