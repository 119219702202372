<template>
    <div :class="[!isMobile ? 'controls' : 'mobile-controls', !isCloseMenu && isMobile  ? 'mobile-controls__closed' : '']">

        <div class="hide">
            <div 
                 @click="$emit('switchMenu')"
                 :class="[!isMobile ? 'hide__button' : 'mobile-hide__button', isCloseMenu  ? !isMobile ? 'hide__button__closed' : 'mobile-hide__button__closed' : '']"
            >
                <div class="mark">
                    <div class="mark__inner">
                        <div :class="[!isMobile ? 'mark__line-top' : 'mobile-mark__line-top']"></div>
                        <div :class="[!isMobile ? 'mark__line-bot' : 'mobile-mark__line-bot']"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="title" v-if="!loading && !isMobile">{{title}}</div>

    </div>
</template>

<script>
export default {
    props: ['isCloseMenu', 'title'],
}
</script>

<style lang="scss" scoped>
.controls {
    position: absolute;
    display: flex;
    height: 32px;
    z-index: 250;
    margin: -35px 0 0 40px;
    transition: transform .25s;
}
.mobile-controls {
    position: absolute;
    display: flex;
    height: 32px;
    z-index: 250;
    right: 20px;
    top: 70px;
    margin: 25px 0 0 20px;
    transition: transform .25s;
    &__closed {
        transform: translateY(140px);
    }
}

.title {
    margin-left: 20px;
    font: 16px proxima-bold;
    line-height: 31px;
}
.hide {
    height: 31px;
    width: 31px;
    border-radius: 31px;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,.16);
    &__button {
        height: 31px;
        width: 31px;
        font: 14px proxima-bold;
        background: white;
        border-radius: 28px;
        line-height: 32px;
        transition: transform .25s;
        transform: rotate(180deg);
        cursor: pointer;
        &__closed {
            transform: rotate(0deg);
        }
        &:hover .mark__line-top {
            background: rgb(250, 213, 50);
        }
        &:hover .mark__line-bot {
            background: rgb(250, 213, 50);
        }
    }
}
.mobile-hide__button {
    height: 31px;
    width: 31px;
    font: 14px proxima-bold;
    background: #e4af1d;
    border-radius: 28px;
    line-height: 32px;
    transition: transform .25s;
    transform: rotate(180deg);
    cursor: pointer;
    &__closed {
        transform: rotate(0deg);
        background: #445f7a;
    }
}
.mark {
    padding-top: 13px;
    margin-left: 12px;
    height: 6px;
    &__inner {
        margin: -2px 0 0 0;
        width: 7px;
        height: 7px;
        transform: rotate(-45deg);        
    }
    &__line-top {
        width: 2px;
        height: 7px;
        background: rgb(15, 42, 72);
        border-radius: 3px;
    }
    &__line-bot {
        width: 7px;
        height: 2px;
        background: rgb(15, 42, 72);
        margin-top: -2px;
        border-radius: 3px;
    }
}
.mobile-mark {
    &__line-top {
        width: 2px;
        height: 7px;
        background: rgb(255, 255, 255);
        border-radius: 3px;
    }
    &__line-bot {
        width: 7px;
        height: 2px;
        background: rgb(247, 247, 247);
        margin-top: -2px;
        border-radius: 3px;
    }
}
</style>