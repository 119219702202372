<template>
    <div v-if="!loading" :class="[!isMobile ? 'side-page' : 'mobile-side', isOpen ? 'side-page__opened' : '']">
        <div class="object__head">
            <div class="close">
                <div class="close__button" @click="$emit('closeHome')">
                    <div class="first-line" />
                    <div class="second-line" />
                </div>
            </div>
        </div>

        <div class="object">
            <Home view="compact" />
        </div>

    </div>
</template>

<script>
import Home from '@/views/Home'

export default {
    props: ['isOpen'],
    components: {
        Home
    },
}
</script>

<style lang="scss" scoped>
.side-page {
    position: fixed;
    right: 0;
    left: auto;
    top: 0;
    bottom: 0;
    width: 25vw;
    background: white;
    box-shadow: -20px 3px 20px rgba(40, 110, 156, 0.12), -20px 3px 20px rgba(40, 110, 156,  0.12);
    z-index: 50;
    transform: translateX(660px);
    transition: transform .25s;
    overflow: auto;
    &__opened {
        transform: translateX(0) !important;
    }
}
.mobile-side {
    position: fixed;
    right: 0;
    left: auto;
    top: 0;
    bottom: 0;
    width: 100%;
    background: white;
    box-shadow: -20px 3px 20px rgba(40, 110, 156, 0.12), -20px 3px 20px rgba(40, 110, 156,  0.12);
    z-index: 350;
    transform: translateX(660px);
    transition: transform .25s;
    overflow-y: auto;
}
.object {
    margin-top: -70px;
    &__head {
        background: #505050;
        height: 70px;
        padding-right: 20px;
        display: flex;
        align-items: center;
    }
}
.close {
    flex: 1 0 auto;
    max-width: 30px;
    height: 30px;
    margin: auto 0 auto auto;
    overflow: hidden;
    &__button {
       cursor: pointer;
        transform: rotate(45deg); 
    }
}
.first-line {
    position: relative;
    width: 30px;
    height: 2px;
    top: 14px;
    background: #9f9f9f;
}
.second-line {
    position: relative;
    width: 2px;
    height: 30px;
    top: -2px;
    left: 14px;
    background: #9f9f9f;
}
</style>