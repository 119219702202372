<template>
    <div :class="['left-side-page', isOpen  ? 'left-side-page--moved' : '']">
        <div class="left-side-spacer" />
        <Categories :class="['side-category', isOpen  ? 'side-category--moved' : '']" />
    </div>
</template>

<script>
import Categories from '@/components/Categories'
import { mapGetters } from 'vuex'

export default {
    props: ['isOpen', 'home'],
    components: {
        Categories,
    },
    computed: {
        ...mapGetters(['homes']),
        isFav() {
            return this.favs.includes(this.home.id);
        },
    },
}
</script>

<style lang="scss" scoped>
.left-side-page {
    width: calc((100vw - 1220px) / 2);
    height: 100%;
    border-right: 1px solid rgb(220, 220, 220);
    transition: transform .25s;
    &--moved {
        position: fixed;
        top: 496px;
    }
}
.left-side-spacer {
    height: 20px;
}
.object__head {
    background: #505050;
    height: 70px;
    padding-right: 20px;
    display: flex;
    align-items: center;
}
.side-category {
    margin-left: 40px;
    &--moved {
        position: fixed;
        top: 158px;
    }
}
</style>